:root[data-theme='light'] {
  --sidebar-logo: var(--brand-1);
}

:root[data-theme='dark'] {
  --sidebar-logo: var(--contrast);
}

.logoWrapper {
  padding-top: 24px;
}

.logoSvg {
  fill: var(--sidebar-logo);
  transition: var(--transition);
}

.logoContainer {
  width: 72px;
  display: flex;
  justify-content: center;
  transition: var(--transition);

  &__gazOpened {
    padding-left: 24px;
    width: 135px;
  }
}

.logoConcealer {
  overflow: hidden;
  transition: var(--transition);

  &__gaz {
    width: 34px;
  }

  &__ink {
    width: 48px;
  }

  &__geo {
    width: 48px;
  }

  &__opened {
    width: 111px;
  }
}
