@use 'src/styles/typography';

:root[data-theme='light'] {
  --well-card-bg-color: var(--white-color);
}

:root[data-theme='dark'] {
  --well-card-bg-color: var(--primary-1);
}

.card {
  background-color: var(--well-card-bg-color);
  width: 100%;
  height: 64px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  padding: 12px 12px 8px 12px;

  &Interactive {
    cursor: pointer;
  }

  &Border {
    width: 100%;
    height: 4px;
    position: absolute;
    top: 0;
    left: 0;
  }

  &Header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &Title {
      @extend %body-m;
      color: var(--contrast);
      width: 100%;
    }

    &Icons {
      min-width: 16px;
      display: flex;
      align-items: center;

      .icon {
        min-width: 16px;
        height: 16px;
        margin-left: 2px;
      }
    }
  }

  &Body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 8px;

    &Text {
      @extend %caption;
      color: var(--contrast);
      opacity: 0.75;
    }

    &Button {
      background: transparent;
      border: none;
      outline: none;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: var(--contrast);
    }
  }
}
