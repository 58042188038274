@import 'src/styles/typography';

.wrapper {
  display: flex;
  color: var(--white-color);
  border-radius: 8px;
  transition: background var(--transition);

  &__inLoading {
    padding: 8px 16px 8px 8px;
    background: rgba(96, 92, 255, 0.15);

    flex-direction: row;
    align-items: center;

    .importInfo {
      margin-left: 8px;
    }
  }

  &__inProgress {
    padding: 8px 16px 8px 8px;
    flex-direction: column;
    background: rgba(96, 92, 255, 0.15);


    flex-direction: row;
    align-items: center;
  }

  &__completedWithError {
    padding: 5px 4px 5px 8px;
    flex-direction: row;
    align-items: center;
    column-gap: 8px;
    background: rgba(235, 87, 87, 0.40);
  }

  &__completed {
    align-items: center;
    flex-direction: row;
    padding: 5px 4px 5px 8px;
    column-gap: 8px;
    background: rgba(33, 150, 83, 0.40);
  }
}

.exclamationMarkIcon {
  color: #EB5757;
}

.importInfo {
  font-size: 14px;
  line-height: 20px;
}

.progressBar {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  line-height: 2px;
}

.goToImportButton {
  @extend .link-m;

  padding: 4px 8px;
  text-decoration: none;
  color: var(--white-color);
}

.cancelImportButton {
  color: var(--brand-1);
  padding: 4px 8px;
  margin-left: 16px;

  svg {
    stroke: currentColor;
  }
}
